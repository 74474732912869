import { Container, Flex, Text } from "@chakra-ui/react";
import React from "react";

const list = [
  "To me, my country represents...",
  "My worst experience as an immigrant has been...",
  "Being in Canada has changed my...",
  "My support system in Canada has looked like...",
  "If I had the option to leave Canada I would...",
  "My first accomploishment is outside of my country of origin was...",
  "When I moved here, I felt that my family became...",
  "Immigration has taught me...",
  "As an immigrant I can say that my identity has...",
  "Before immigrating here, I wish I knew...",
  "As an immigrant I feel...",
  "After immigrating, my family dynamic became...",
  "In Canada I discovered that I am...",
  "Canada is...",
];

const random = Math.floor(Math.random() * list.length);

const Prompts = () => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      bg="#f1fdf7"
      fontFamily="Garamond"
      padding="64px"
      width="100%"
    >
      <Text>{list[random]}</Text>
    </Flex>
  );
};

export default Prompts;
